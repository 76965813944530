import { FormControl, withStyles } from "@material-ui/core";

const CustomInput = withStyles((theme) => ({
	root: {
		background: '#f4f4f4',
		margin: '10px 0',

		'& *': {
			fontFamily: 'nunito'
		},

		'& label': {

			[theme.breakpoints.down('xs')]: {
				fontSize: 13,
			}
		},

		'& .MuiInputBase-root': {
			height: 57,

			'& .Mui-focused': {
				background: '#fff'
			}
			
		},

		'& fieldset': {
			border: '2px solid #d4d2d2'
		},

		'& .MuiFormLabel-root.Mui-focused': {
			color: '#000'
		},

		'& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
			borderColor: '#000',
		},

		'&.is_valid': {
			'& .MuiFormLabel-root': {
				color: '#2ecc71 !important'
			},

			'& fieldset': {
				border: '2px solid #2ecc71 !important'
			},
		},

		'&.invalid': {
			'& .MuiFormLabel-root': {
				color: '#d81717 !important'
			},

			'& fieldset': {
				border: '2px solid #d81717 !important'
			},
		}
	}
}))(FormControl)

export default CustomInput