import * as yup from 'yup'

const helper_validate_form = {
	login: async function (data) {
		const obj = {
			form: false,
			username: null,
			password: null
		}
		
		const schema = yup.object().shape({
			username: yup.string().required(),
			password: yup.string().required().min(8, 'Data incompleta.'),
		});

		this.validateInput(data, obj, schema)


		const validate = await schema.validate(data)
			.then(() => { return true })
			.catch(() => { return false })

		obj.form = validate

		return obj
	},

	

	validateInput: async function (data, obj, schema) {
		Object.keys(data).forEach(async (key) => {
			if (data[key] !== '') {
				const validateInput = await schema.validateAt(key, data)
					.then(() => { return true })
					.catch(() => { return false })

				obj[key] = validateInput

			} else {
				obj[key] = null
			}
		})
	}
}

export default helper_validate_form