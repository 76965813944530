import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist'

 const reducersFile = reducers => {
  const persistedReducer = persistReducer({
    key: 'lsa_tk_embalei',
    storage,
    whitelist: ['auth'],
  }, reducers);

  return persistedReducer;
}

export default reducersFile
