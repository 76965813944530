import { CssBaseline } from "@material-ui/core";

import 'react-toastify/dist/ReactToastify.css'
import { ToastContainer } from "react-toastify";

import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";

import GlobalStyle from "./assets/css/GlobalStyles";

import { ConnectedRouter } from "connected-react-router";
import Routes from "./Routes";

import history from "./services/history";

import { store, persistor } from './store'

function App() {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <ConnectedRouter history={history}>
          <GlobalStyle />
          <CssBaseline />
          <ToastContainer autoClose={2000} />
          <Routes />
        </ConnectedRouter>
      </PersistGate>
    </Provider>
  );
}

export default App;
