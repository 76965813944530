import { Box, Container, Typography } from "@material-ui/core";
import { useCallback, useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Redirect } from "react-router";

import * as Actions from "../../../store/modules/auth/actions";
import stylePageSuccess from "./style";

const OrderSuccess = ({ signOut, location }) => {
  const style = stylePageSuccess();

  const [success, setSuccess] = useState(null);
  const [time, setTime] = useState(5);
  const dispatch = useDispatch();

  const timer = useCallback(() => {
    if (time > 0) {
      const t = time - 1;
      setTime(t);
    } else {
      dispatch(signOut());
    }
  }, [dispatch, signOut, time]);

  useEffect(() => {
    if (success) {
      setTimeout(() => timer(), 1000);
    }
  }, [success, time, timer]);

  useEffect(() => {
    if (location.state !== undefined) {
      if (location.state.success) {
        setSuccess(true);
        setTimeout(() => timer(), 1000);
      } else {
        setSuccess(false);
      }
    } else {
      setSuccess(false);
    }
  }, [location.state, timer]);

  return (
    <>
      {success && (
        <Container maxWidth="md">
          <Box>
            <Typography className={style.labelSuccess}>
              Obrigado, pedidos confirmados com sucesso!
            </Typography>
          </Box>
          <Box className={style.labelTimer}>
            <Typography align="center">Sua sessão encerra em {time}</Typography>
          </Box>
        </Container>
      )}

      {success === false && <Redirect to="/" />}
    </>
  );
};

export default connect(null, Actions)(OrderSuccess);
