import { createStore, applyMiddleware, compose } from 'redux';

 const createStoreFile = (reducers, middlewares) => {

	const enhancer = compose(
		applyMiddleware(
			...middlewares,
		)
	)

	return createStore(reducers, enhancer);
};

export default createStoreFile