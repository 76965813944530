import { persistStore } from 'redux-persist'
import createSagaMiddleware from 'redux-saga';
import createStore from './createStore';

import rootReducer from './modules/rootReducer';
import rootSaga from './modules/rootSaga';
import persistReducer from './persistReducer';

import thunk from 'redux-thunk'
import { connectRouter, routerMiddleware } from 'connected-react-router';
import history from '../services/history';


const sagaMiddleware = createSagaMiddleware();

const middlewares = [sagaMiddleware, thunk, routerMiddleware(history) ];

const store = createStore(connectRouter(history)(persistReducer(rootReducer)), middlewares);
const persistor = persistStore(store);

sagaMiddleware.run(rootSaga);

export { store, persistor };